import {
  defineLeanCompProps,
  InferExtendedPropsSchema,
} from '@wix/lean-components-infra';
import { OnKeyPressPayload } from './corvid/keyPressPropsSDKFactory';

export const PREFIX_AND_SUFFIX_TYPES = {
  icon: 'Icon',
  text: 'Text',
};
const prefixAndSuffixTypeOptions = [
  ...([...Object.values(PREFIX_AND_SUFFIX_TYPES)] as const),
];
export type PrefixAndSuffixTypeOptions =
  (typeof prefixAndSuffixTypeOptions)[number];

const SIZES: { [key: string]: string } = {
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
};

const sizes = [...([...Object.values(SIZES)] as const)];

export const LABEL_POSITIONS: { [key: string]: string } = {
  top: 'Top',
  left: 'Left',
  right: 'Right',
};
const labelPositions = [...([...Object.values(LABEL_POSITIONS)] as const)];

export const BORDERS: { [key: string]: string } = {
  standard: 'Default',
  round: 'Round',
  bottomLine: 'Line',
  none: 'None',
};

const borders = [...([...Object.values(BORDERS)] as const)];

/**
 * The props.ts file is the contract between the React component and Wix ecosystem
 * Through this file you can declare panels, SDKs and behaviours.
 * To learn more about the various capabillities, read [lean components guide](https://bo.wix.com/pages/editor-elements-docs/docs/development/lean-components/index)
 */
const props = defineLeanCompProps(({ schemaBuilder }) => ({
  label: schemaBuilder.shortString().default('Event name'),
  placeholder: schemaBuilder.shortString().default('Enter your event name...'),
  value: schemaBuilder.shortString().default(''),
  showTooltip: schemaBuilder.boolean().default(false),
  infoContent: schemaBuilder
    .longString()
    .default('This message explains the purpose of a field.'),
  showPrefix: schemaBuilder.boolean().default(false),
  prefixType: schemaBuilder
    .stringEnum<PrefixAndSuffixTypeOptions>(prefixAndSuffixTypeOptions)
    .default(PREFIX_AND_SUFFIX_TYPES.icon),
  prefixIcon: schemaBuilder.svg(),
  prefixValue: schemaBuilder.shortString().default('Units'),
  showSuffix: schemaBuilder.boolean().default(false),
  suffixType: schemaBuilder
    .stringEnum<PrefixAndSuffixTypeOptions>(prefixAndSuffixTypeOptions)
    .default(PREFIX_AND_SUFFIX_TYPES.icon),
  suffixIcon: schemaBuilder.svg(),
  suffixValue: schemaBuilder.shortString().default('.com'),
  required: schemaBuilder.boolean().default(false),
  setMaxLength: schemaBuilder.boolean().default(false),
  maxLength: schemaBuilder.integer().default(20),
  disabled: schemaBuilder.boolean().default(false),
  readOnly: schemaBuilder.boolean().default(false),
  size: schemaBuilder.stringEnum(sizes).default(SIZES.medium),
  border: schemaBuilder.stringEnum(borders).default(BORDERS.standard),
  labelPosition: schemaBuilder
    .stringEnum(labelPositions)
    .default(LABEL_POSITIONS.top),
}))
  .definePanels(({ settingsPanel, layoutPanel }) => {
    settingsPanel?.controls.createSection(['placeholder']);
    settingsPanel?.controls.createSection(['value']);
    settingsPanel?.controls.createSection(['showTooltip', 'infoContent'], {
      name: 'dsgnsys_WsrInput_showTooltip_section_label',
    });
    settingsPanel?.controls.infoContent.isVisible(
      ({ props: { showTooltip } }) => showTooltip,
    );
    settingsPanel?.controls.createSection(
      [
        'showPrefix',
        'prefixType',
        'prefixIcon',
        'prefixValue',
        'showSuffix',
        'suffixType',
        'suffixIcon',
        'suffixValue',
      ],
      {
        name: 'dsgnsys_WsrInput_prefixAndSuffix_section_label',
      },
    );
    settingsPanel?.controls.prefixType.modify({
      controlType: 'radioButtons',
    });
    settingsPanel?.controls.prefixType.isVisible(
      ({ props: { showPrefix } }) => showPrefix,
    );
    settingsPanel?.controls.prefixIcon.isVisible(
      ({ props: { prefixType, showPrefix } }) =>
        showPrefix && prefixType === 'Icon',
    );
    settingsPanel?.controls.prefixValue.isVisible(
      ({ props: { prefixType, showPrefix } }) =>
        showPrefix && prefixType === 'Text',
    );
    settingsPanel?.controls.suffixType.isVisible(
      ({ props: { showSuffix } }) => showSuffix,
    );
    settingsPanel?.controls.suffixType.modify({
      controlType: 'radioButtons',
    });
    settingsPanel?.controls.suffixIcon.isVisible(
      ({ props: { suffixType, showSuffix } }) =>
        showSuffix && suffixType === 'Icon',
    );
    settingsPanel?.controls.suffixValue.isVisible(
      ({ props: { suffixType, showSuffix } }) =>
        showSuffix && suffixType === 'Text',
    );
    settingsPanel?.controls.createSection(
      ['required', 'setMaxLength', 'maxLength'],
      {
        name: 'dsgnsys_WsrInput_rules_section_label',
      },
    );
    settingsPanel?.controls.maxLength.isVisible(
      ({ props: { setMaxLength } }) => setMaxLength,
    );
    settingsPanel?.controls.createSection(['disabled', 'readOnly'], {
      name: 'dsgnsys_WsrInput_status_section_label',
    });
    settingsPanel?.controls.prefixIcon.modify({
      preset: 'WSR_ICONS',
    });
    settingsPanel?.controls.suffixIcon.modify({
      preset: 'WSR_ICONS',
    });
    settingsPanel?.controls.omit('size', 'border', 'labelPosition');
    layoutPanel?.controls.push('size', 'border', 'labelPosition');
    layoutPanel?.controls.size.modify({
      controlType: 'radioButtons',
    });
    layoutPanel?.controls.labelPosition.modify({
      controlType: 'radioButtons',
    });
  })
  .defineSdk(({ sdkMixin }) => ({
    mixins: [sdkMixin.changeable],
  }));

export type InputSDKActions = {
  onKeyPress(payload: OnKeyPressPayload): void;
};

type IWsrInputVeloProps = {
  error: boolean;
  warning: boolean;
  loading: boolean;
  errorMessage: string;
  warningMessage: string;
  loadingMessage: string;
  disabled: boolean;
  readOnly: boolean;
};

export type IWsrInputProps = InferExtendedPropsSchema<typeof props> &
  InputSDKActions &
  IWsrInputVeloProps & { className?: string };

export default props;
