import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWutTabbedContentProps,
  IWutTabbedContentMapperProps,
  IWutTabbedContentControllerProps,
  IWutTabbedContentStateValues,
} from '../../../components/WutTabbedContent/WutTabbedContent.props';

export default withCompController<
  IWutTabbedContentMapperProps,
  IWutTabbedContentControllerProps,
  IWutTabbedContentProps,
  IWutTabbedContentStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
