import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWutAvatarGroupProps,
  IWutAvatarGroupMapperProps,
  IWutAvatarGroupControllerProps,
  IWutAvatarGroupStateValues,
} from '../../../components/WutAvatarGroup/WutAvatarGroup.props';

export default withCompController<
  IWutAvatarGroupMapperProps,
  IWutAvatarGroupControllerProps,
  IWutAvatarGroupProps,
  IWutAvatarGroupStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
