import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuDatePickerProps,
  IWbuDatePickerMapperProps,
  IWbuDatePickerControllerProps,
  IWbuDatePickerStateValues,
} from '../../../components/WbuDatePicker/WbuDatePicker.props';

export default withCompController<
  IWbuDatePickerMapperProps,
  IWbuDatePickerControllerProps,
  IWbuDatePickerProps,
  IWbuDatePickerStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
