import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      illustration: {},
      url: '',
      textButtonLabel: 'Start Creating',
      description:
        'Create your own lightboxes and link to them for any place in your site.',
      label: 'Link to Lightbox',
    },
    ...compData,
  };
});

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({ props, stateRefs });
