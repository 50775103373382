import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuLeftTextButtonProps,
  IWbuLeftTextButtonMapperProps,
  IWbuLeftTextButtonControllerProps,
  IWbuLeftTextButtonStateValues,
} from '../../../components/WbuLeftTextButton/WbuLeftTextButton.props';

export default withCompController<
  IWbuLeftTextButtonMapperProps,
  IWbuLeftTextButtonControllerProps,
  IWbuLeftTextButtonProps,
  IWbuLeftTextButtonStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
