import * as React from 'react';
import Heading, { Size } from 'wix-style-react/dist/src/Heading';
import { getDataAttributes } from '../../../common/utils';
import type { IWsrHeadingProps } from '../WsrHeading.props';
import { SIZES } from '../WsrHeading.props';
import { st, classes } from './WsrHeading.st.css';

const WsrHeading: React.FC<IWsrHeadingProps> = props => {
  const { id, className, text, size, invertColor, textAlignment } = props;
  const sizeValue = Object.keys(SIZES).find(key => SIZES[key] === size) as Size;

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={st(
        classes.root,
        {
          textAlignment: textAlignment.toLowerCase(),
        },
        className,
      )}
    >
      <Heading light={invertColor} size={sizeValue}>
        {text}
      </Heading>
    </div>
  );
};

export default WsrHeading;
