import * as React from 'react';
import {
  Composites,
  TextLabel,
  ColorPickerInput,
} from '@wix/wix-base-ui/lib/esm';

import type { IWbuColorSelectorProps } from '../WbuColorSelector.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import './WbuColorSelector.global.scss';
import { getDataAttributes } from '../../../common/utils';

const WbuColorSelector: React.FC<IWbuColorSelectorProps> = props => {
  const { onClick, id, className, label, color, divider, onColorChange } =
    props;

  const [isMount, setIsMount] = React.useState(false);
  React.useEffect(() => {
    if (isMount) {
      onColorChange?.({ type: 'onColorChange' });
    }
    setIsMount(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      divider={divider}
    >
      <Composites.ColorSelectLabeled>
        <TextLabel type="T02" value={label} />
        <ColorPickerInput onClick={onClick} value={color} />
      </Composites.ColorSelectLabeled>
    </WbuContainer>
  );
};

export default WbuColorSelector;
