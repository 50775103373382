import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWsrMessageModalLayoutProps,
  IWsrMessageModalLayoutMapperProps,
  IWsrMessageModalLayoutControllerProps,
  IWsrMessageModalLayoutStateValues,
} from '../../../components/WsrMessageModalLayout/WsrMessageModalLayout.props';

export default withCompController<
  IWsrMessageModalLayoutMapperProps,
  IWsrMessageModalLayoutControllerProps,
  IWsrMessageModalLayoutProps,
  IWsrMessageModalLayoutStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
