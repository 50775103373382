import * as React from 'react';
import {
  Composites,
  TextLabel,
  CornerRadiusInput,
  InfoIcon,
} from '@wix/wix-base-ui/lib/esm';

import WbuContainer from '../../WbuContainer/WbuContainer';
import { IWbuCornerRadiusProps } from '../WbuCornerRadius.props';

import './WbuCornerRadius.global.scss';
import { getDataAttributes } from '../../../common/utils';

const WbuCornerRadius: React.FC<IWbuCornerRadiusProps> = props => {
  const {
    id,
    className,
    label,
    tooltip,
    value,
    min,
    max,
    constrainCorners,
    onChange,
    updateComponentPropsInViewer,
    divider,
    editorType,
  } = props;

  const _onChange = (val: number) => {
    updateComponentPropsInViewer({ value: val });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      divider={divider}
      editorType={editorType}
    >
      <Composites.CornerRadiusInputLabeled>
        <InfoIcon text={tooltip} />
        <TextLabel type="T02" value={label} />
        <CornerRadiusInput
          value={`${value}`}
          isInitiallyLocked={constrainCorners}
          min={min}
          max={max}
          onChange={_onChange}
        />
      </Composites.CornerRadiusInputLabeled>
    </WbuContainer>
  );
};

export default WbuCornerRadius;
