import {
  defineLeanCompProps,
  InferExtendedPropsSchema,
} from '@wix/lean-components-infra';

export const SIZES: { [key: string]: string } = {
  large: 'Large',
  medium: 'Medium',
  small: 'Small',
} as const;
const sizes = Object.values(SIZES);

export const SKINS: { [key: string]: string } = {
  standard: 'Standard',
  success: 'Success',
  error: 'Destructive',
} as const;
const skins = Object.values(SKINS);

export const LABEL_POSITIONS: { [key: string]: string } = {
  top: 'On top',
  left: 'Before',
  right: 'After',
} as const;
const labelPositions = Object.values(LABEL_POSITIONS);

const props = defineLeanCompProps(({ schemaBuilder }) => ({
  label: schemaBuilder.shortString().default('Toggle Switch'),
  labelPosition: schemaBuilder
    .stringEnum(labelPositions)
    .default(LABEL_POSITIONS.right),
  showTooltip: schemaBuilder.boolean().default(false),
  tooltip: schemaBuilder.longString().default(''),
  checked: schemaBuilder.boolean().default(true),
  size: schemaBuilder.stringEnum(sizes).default(SIZES.large),
  skin: schemaBuilder.stringEnum(skins).default(SKINS.standard),
}))
  .definePanels(({ settingsPanel, layoutPanel }) => {
    layoutPanel?.controls.push('labelPosition');
    settingsPanel?.controls.omit('labelPosition');
    settingsPanel?.controls.createSection(['showTooltip', 'tooltip']);
    settingsPanel?.controls.createSection(['size'], {
      name: 'dsgnsys_WsrToggleSwitch_size_section_label',
    });
    settingsPanel?.controls.createSection(['skin'], {
      name: 'dsgnsys_WsrToggleSwitch_style_section_label',
    });
    settingsPanel?.controls.tooltip.isVisible(
      ({ props: { showTooltip } }) => showTooltip,
    );
    layoutPanel?.controls.labelPosition.modify({
      controlType: 'radioButtons',
    });
    settingsPanel?.controls.size.modify({ controlType: 'radioButtons' });
  })
  .defineSdk(({ sdkMixin }) => ({
    mixins: [sdkMixin.changeable],
  }));

export type IWsrToggleSwitchProps = InferExtendedPropsSchema<typeof props> & {
  disabled: boolean;
  className?: string;
};

export default props;
