import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWsrInputProps,
  IWsrInputMapperProps,
  IWsrInputControllerProps,
  IWsrInputStateValues,
} from '../../../components/WsrInput/WsrInput.props';

export default withCompController<
  IWsrInputMapperProps,
  IWsrInputControllerProps,
  IWsrInputProps,
  IWsrInputStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
