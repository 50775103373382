import * as React from 'react';
import Button from 'wix-style-react/dist/src/Button';
import Loader from 'wix-style-react/dist/src/Loader';

import type { IWsrButtonProps } from '../WsrButton.props';
import { usePlatformIcon, usePlatformLink } from '../../../providers';
import { getDataAttributes } from '../../../common/utils';

const WsrButton: React.FC<IWsrButtonProps> = props => {
  const {
    id,
    className,
    value,
    skin,
    size,
    secondary,
    onMouseEnter,
    onMouseLeave,
    disabled,
    loading,
    link,
    onClick = () => {},
    ...rest
  } = props;

  const linkProps = usePlatformLink(link);
  const prefix = usePlatformIcon(rest.prefixIcon);
  const suffix = usePlatformIcon(rest.suffixIcon);

  return (
    <div id={id} {...getDataAttributes(props)} className={className}>
      <Button
        {...linkProps}
        as={linkProps.href ? 'a' : undefined}
        skin={skin}
        disabled={disabled}
        size={size}
        priority={secondary ? 'secondary' : 'primary'}
        onClick={onClick}
        prefixIcon={prefix}
        suffixIcon={suffix}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {loading ? <Loader size="tiny" /> : value}
      </Button>
    </div>
  );
};

export default WsrButton;
