import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      skin: 'Standard',
      secondary: false,
      size: 'Medium',
      disabled: false,
      linkUrl: '',
      tooltipText: 'More Actions',
      icon: {},
    },
    ...compData,
  };
});

const css = withCompInfo()([], () => {
  return {
    height: 'auto',
    width: 'auto',
  };
});

export default createComponentMapperModel({
  props,
  css,
});
