import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWutAvatarProps,
  IWutAvatarMapperProps,
  IWutAvatarControllerProps,
  IWutAvatarStateValues,
} from '../../../components/WutAvatar/WutAvatar.props';

export default withCompController<
  IWutAvatarMapperProps,
  IWutAvatarControllerProps,
  IWutAvatarProps,
  IWutAvatarStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
