import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWutRadioButtonProps,
  IWutRadioButtonMapperProps,
  IWutRadioButtonControllerProps,
  IWutRadioButtonStateValues,
} from '../../../components/WutRadioButton/WutRadioButton.props';

export default withCompController<
  IWutRadioButtonMapperProps,
  IWutRadioButtonControllerProps,
  IWutRadioButtonProps,
  IWutRadioButtonStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
