import * as React from 'react';
import Divider from 'wix-style-react/dist/src/Divider';
import { getDataAttributes } from '../../../common/utils';
import type { IWsrDividerProps } from '../WsrDivider.props';
import { st, classes } from './WsrDivider.st.css';

const WsrDivider: React.FC<IWsrDividerProps> = props => {
  const { id, className, direction, skin } = props;

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={st(classes.root, { direction }, className)}
    >
      <Divider skin={skin} direction={direction} />
    </div>
  );
};

export default WsrDivider;
