import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(
  ['compData', 'compLayout'],
  ({ compData, compLayout }) => {
    return {
      ...{
        height: compLayout.height,
      },
      ...compData,
    };
  },
);

const css = withCompInfo<any, any>()(['compLayout'], ({ compLayout }) => {
  return {
    '--height': `${compLayout.height}px`,
    ...compLayout,
  };
});

export default createComponentMapperModel({
  props,
  css,
});
