import {
  defineLeanCompProps,
  InferExtendedPropsSchema,
} from '@wix/lean-components-infra';

export const SIZES: { [key: string]: string } = {
  extraLarge: 'H1 - Page title',
  large: 'H2 - Section title',
  medium: 'H3 - Card title',
  small: 'H4 - Card content tile',
  tiny: 'H5 - Card section title',
  extraTiny: 'H6 - Caption',
};

const sizes = Object.values(SIZES);

const ALIGNS = {
  LEFT: 'Left',
  CENTER: 'Center',
  RIGHT: 'Right',
};
const aligns = Object.values(ALIGNS);

const props = defineLeanCompProps(({ schemaBuilder }) => ({
  text: schemaBuilder.longString().default('Heading'),
  size: schemaBuilder.stringEnum(sizes).default(SIZES.extraLarge),
  invertColor: schemaBuilder.boolean().default(false),
  textAlignment: schemaBuilder.stringEnum(aligns).default(ALIGNS.LEFT),
})).definePanels(({ settingsPanel }) => {
  settingsPanel?.controls.createSection(['size'], {
    name: 'dsgnsys_WsrHeading_size_section_title',
  });
  settingsPanel?.controls.createSection(['invertColor', 'textAlignment'], {
    name: 'dsgnsys_WsrHeading_style_section_title',
  });
  settingsPanel?.controls.textAlignment.modify({
    controlType: 'radioButtons',
  });
});

export type IWsrHeadingProps = InferExtendedPropsSchema<typeof props> & {
  className?: string;
};

export default props;
