import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuRadioButtonGroupProps,
  IWbuRadioButtonGroupMapperProps,
  IWbuRadioButtonGroupControllerProps,
  IWbuRadioButtonGroupStateValues,
} from '../../../components/WbuRadioButtonGroup/WbuRadioButtonGroup.props';

export default withCompController<
  IWbuRadioButtonGroupMapperProps,
  IWbuRadioButtonGroupControllerProps,
  IWbuRadioButtonGroupProps,
  IWbuRadioButtonGroupStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
