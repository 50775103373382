import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      skin: 'standard',
      size: 'medium',
      disabled: false,
      checked: true,
      showTooltip: false,
      tooltip: '',
      labelPosition: 'right',
      label: 'Toggle Switch',
    },
    ...compData,
  };
});

const css = withCompInfo()([], () => {
  return {
    height: 'auto',
    width: 'auto',
  };
});

export default createComponentMapperModel({
  props,
  css,
});
