import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWsrPageProps,
  IWsrPageMapperProps,
  IWsrPageControllerProps,
  IWsrPageStateValues,
} from '../../../components/WsrPage/WsrPage.props';

export default withCompController<
  IWsrPageMapperProps,
  IWsrPageControllerProps,
  IWsrPageProps,
  IWsrPageStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
