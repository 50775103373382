import {
  defineLeanCompProps,
  InferExtendedPropsSchema,
} from '@wix/lean-components-infra';

export const STATUSES: { [key: string]: string } = {
  none: 'Default',
  error: 'Error',
  warning: 'Warning',
  loading: 'Loading',
};

const statuses = [...([...Object.values(STATUSES)] as const)];

export const ACTIVE_STATES: {
  [key: string]: {
    value: string;
    readOnly: boolean;
    disabled: boolean;
  };
} = {
  ACTIVE: {
    value: 'Active',
    readOnly: false,
    disabled: false,
  },
  READ_ONLY: {
    value: 'Read only',
    readOnly: true,
    disabled: false,
  },
  DISABLED: {
    value: 'Disabled',
    readOnly: false,
    disabled: true,
  },
};

const activeStates = Object.values(ACTIVE_STATES).map(({ value }) => value);

const SIZES: { [key: string]: string } = {
  large: 'Large',
  medium: 'Medium',
  small: 'Small',
};

const sizes = [...([...Object.values(SIZES)] as const)];

export const BORDERS: { [key: string]: string } = {
  standard: 'Standard',
  round: 'Round',
  bottomLine: 'Underline only',
  none: 'None',
};

const borders = [...([...Object.values(BORDERS)] as const)];

const props = defineLeanCompProps(
  ({ schemaBuilder }) => ({
    label: schemaBuilder.shortString().default('Date Picker'),
    placeholderText: schemaBuilder.shortString().default(''),
    showTooltip: schemaBuilder.boolean().default(false),
    tooltipContent: schemaBuilder.longString().default(''),
    clearButton: schemaBuilder.boolean().default(false),
    status: schemaBuilder.stringEnum(statuses).default(STATUSES.none),
    errorStatusMessage: schemaBuilder.longString().default(''),
    warningStatusMessage: schemaBuilder.longString().default(''),
    loadingStatusMessage: schemaBuilder.longString().default(''),
    activeState: schemaBuilder
      .stringEnum(activeStates)
      .default(ACTIVE_STATES.ACTIVE.value),
    required: schemaBuilder.boolean().default(false),
    size: schemaBuilder.stringEnum(sizes).default(SIZES.medium),
    border: schemaBuilder.stringEnum(borders).default(BORDERS.standard),
  }),
  { dmPublish: 'draft' },
)
  .defineSdk(({ sdkMixin }) => ({
    mixins: [sdkMixin.changeable],
  }))
  .definePanels(({ settingsPanel }) => {
    settingsPanel?.controls.tooltipContent.isVisible(
      ({ props: { showTooltip } }) => showTooltip,
    );
    settingsPanel?.controls.errorStatusMessage.isVisible(
      ({ props: { status } }) => status === 'Error',
    );
    settingsPanel?.controls.warningStatusMessage.isVisible(
      ({ props: { status } }) => status === 'Warning',
    );
    settingsPanel?.controls.loadingStatusMessage.isVisible(
      ({ props: { status } }) => status === 'Loading',
    );
    settingsPanel?.controls.activeState.modify({
      controlType: 'radioButtons',
    });
    settingsPanel?.controls.size.modify({
      controlType: 'radioButtons',
    });
  });

export type IWsrDatePickerProps = InferExtendedPropsSchema<
  typeof props,
  { value?: Date }
> & { value?: Date; className?: string };

export default props;
