import * as React from 'react';
import {
  Composites,
  TextLabel,
  InfoIcon,
  RadioButtons,
} from '@wix/wix-base-ui/lib/esm';
import type { ManagedOptionDataType } from '@wix/editor-elements-types/leanComponents';
import type { IWbuRadioButtonGroupProps } from '../WbuRadioButtonGroup.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import './WbuRadioButtonGroup.global.scss';
import { getDataAttributes } from '../../../common/utils';

const WbuRadioButtonGroup: React.FC<IWbuRadioButtonGroupProps> = props => {
  const {
    id,
    className,
    fieldTitle,
    tooltipInfo,
    options,
    divider,
    updateComponentPropsInViewer,
    onChange,
    editorType,
  } = props;

  const _onChange = (selectedOptionValue: string) => {
    const newOptions = options.map(option => {
      if (option.value === selectedOptionValue) {
        return {
          ...option,
          isDefault: true,
        };
      }
      return {
        ...option,
        isDefault: false,
      };
    });
    updateComponentPropsInViewer({ options: newOptions });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  const selectedOption = options.find(
    (option: ManagedOptionDataType) => option.isDefault,
  );

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      divider={divider}
      editorType={editorType}
    >
      <Composites.RadioButtonsLabeled>
        {tooltipInfo && <InfoIcon text={tooltipInfo} />}
        <TextLabel type="T02" value={fieldTitle} />
        <RadioButtons
          value={selectedOption ? selectedOption.value : undefined}
          onChange={_onChange}
          options={options}
        />
      </Composites.RadioButtonsLabeled>
    </WbuContainer>
  );
};

export default WbuRadioButtonGroup;
