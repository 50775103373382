import React from 'react';
import { ColorPickerBase } from 'wix-ui-tpa/dist/src/components/ColorPicker/ColorPicker.base';
import { ColorPickerBaseProps } from 'wix-ui-tpa/dist/src/components/ColorPicker/types';
import { getDataAttributes } from '../../../common/utils';
import type { IWutColorPickerProps } from '../WutColorPicker.types';
import { st, classes, vars } from './style/WutColorPicker.component.st.css';

const WutColorPicker: React.FC<IWutColorPickerProps> = props => {
  const {
    id,
    className,
    stylableClassName,
    colors,
    onChange,
    size,
    updateComponentPropsInViewer,
  } = props;

  const _onChange: ColorPickerBaseProps['onChange'] = (e, colorId) => {
    const newColors = colors.map(color => {
      if (color.value === colorId) {
        return {
          ...color,
          isDefault: true,
        };
      }
      return {
        ...color,
        isDefault: false,
      };
    });
    updateComponentPropsInViewer({ colors: newColors });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };
  const style = { [vars['color-dot-size']]: `${size}px` };

  return (
    <div
      id={id}
      className={className}
      {...getDataAttributes(props)}
      style={style}
    >
      <ColorPickerBase
        className={st(classes.root, stylableClassName)}
        name={id}
        onChange={_onChange}
      >
        {colors.map(color => (
          <ColorPickerBase.Item
            id={color.value}
            value={color.value}
            aria-label={color.label}
            checked={color.isDefault}
            disabled={color.disabled}
            isCrossedOut={color.unavailable}
          />
        ))}
      </ColorPickerBase>
    </div>
  );
};

export default WutColorPicker;
