import * as React from 'react';
import Input, {
  InputStatus,
  InputSize,
  InputProps,
} from 'wix-style-react/dist/src/Input';
import FormField, { LabelPlacement } from 'wix-style-react/dist/src/FormField';
import type { IWsrInputProps } from '../WsrInput.props';
import {
  PREFIX_AND_SUFFIX_TYPES,
  BORDERS,
  LABEL_POSITIONS,
} from '../WsrInput.props';
import { getDataAttributes } from '../../../common/utils';
import { usePlatformIcon } from '../../../providers';

export const STATUSES: { [key: string]: string } = {
  error: 'error',
  warning: 'warning',
  loading: 'loading',
};

const generateIconElement = (
  icon: React.ReactNode,
  text: string,
  type: string,
) => {
  if (type === PREFIX_AND_SUFFIX_TYPES.icon && icon) {
    return <Input.IconAffix>{icon}</Input.IconAffix>;
  }

  if (type === PREFIX_AND_SUFFIX_TYPES.text) {
    return <Input.Affix>{text}</Input.Affix>;
  }

  return null;
};
/**
 * As a rule of thumb, we use functional components, and use React's hooks and forwardRef for more advanced capabillities.
 * To learn more about React components guidelines, read [the code-guidelines](https://bo.wix.com/pages/editor-elements-docs/docs/development/contributing#code-guidelines)
 */
const WsrInput: React.FC<IWsrInputProps> = props => {
  const {
    id,
    className,
    label,
    value,
    placeholder,
    prefixIcon,
    prefixValue,
    prefixType,
    suffixIcon,
    suffixType,
    suffixValue,
    setMaxLength,
    onChange,
    maxLength,
    size,
    labelPosition,
    required,
    infoContent,
    showTooltip,
    border,
    error,
    warning,
    loading,
    errorMessage,
    warningMessage,
    loadingMessage,
    disabled,
    readOnly,
    updateComponentPropsInViewer,
    onKeyPress,
  } = props;
  const [charCount, setCharCount] = React.useState(0);
  const [localValue, setLocalValue] = React.useState(value);

  React.useEffect(() => {
    if (maxLength && value.length > maxLength) {
      return;
    }
    setLocalValue(value);
  }, [value, maxLength]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (maxLength && event.target.value.length > maxLength) {
      return;
    }
    updateComponentPropsInViewer({ value: event.target.value });
    setCharCount(event.target.value.length);
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  const handleKeyDown: InputProps['onKeyDown'] = event => {
    return onKeyPress?.({ type: 'keyPress', key: event.key });
  };

  const charCountProp =
    maxLength === 0 || !setMaxLength ? undefined : maxLength - charCount;

  const inputStatus = ((error && STATUSES.error) ||
    (warning && STATUSES.warning) ||
    (loading && STATUSES.loading)) as InputStatus;

  const statusMessage =
    (error && errorMessage) ||
    (warning && warningMessage) ||
    (loading && loadingMessage);

  const borderValue = Object.keys(BORDERS).find(
    key => BORDERS[key] === border,
  ) as 'standard' | 'round' | 'bottomLine' | 'none';

  const labelPositionValue = Object.keys(LABEL_POSITIONS).find(
    key => LABEL_POSITIONS[key] === labelPosition,
  ) as LabelPlacement;

  return (
    <div id={id} {...getDataAttributes(props)} className={className}>
      <FormField
        label={label}
        infoContent={showTooltip && infoContent}
        labelPlacement={labelPositionValue}
        charCount={charCountProp}
        required={required}
      >
        <Input
          maxLength={maxLength}
          value={localValue}
          placeholder={placeholder}
          onChange={handleOnChange}
          status={inputStatus || undefined}
          statusMessage={statusMessage}
          size={size.toLowerCase() as InputSize}
          readOnly={readOnly}
          disabled={disabled}
          prefix={generateIconElement(
            usePlatformIcon(prefixIcon),
            prefixValue,
            prefixType,
          )}
          suffix={generateIconElement(
            usePlatformIcon(suffixIcon),
            suffixValue,
            suffixType,
          )}
          border={borderValue}
          onKeyDown={handleKeyDown}
        />
      </FormField>
    </div>
  );
};

export default WsrInput;
