import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      tooltip: '',
      iconType: 'None',
      description: 'This is a description text',
      label: 'Label Title',
    },
    ...compData,
  };
});

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({ props, stateRefs });
