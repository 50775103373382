import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWutColorPickerProps,
  IWutColorPickerMapperProps,
  IWutColorPickerControllerProps,
  IWutColorPickerStateValues,
} from '../../../components/WutColorPicker/WutColorPicker.props';

export default withCompController<
  IWutColorPickerMapperProps,
  IWutColorPickerControllerProps,
  IWutColorPickerProps,
  IWutColorPickerStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
