import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      dividerType: 'None',
      minimumValue: 1,
      enableReorder: true,
      tooltipMessage: '',
      fieldTitle: 'Column controller',
    },
    ...{},
    ...compData,
  };
});

export const stateRefs = withStateRefsValues(['editorType']);

const css = withCompInfo()(['compData'], ({ compData }) => {
  return {
    height: '150px',
    width: compData.loading ? undefined : '288px',
  };
});

export default createComponentMapperModel({
  props,
  stateRefs,
  css,
});
