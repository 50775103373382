import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuTextInputProps,
  IWbuTextInputMapperProps,
  IWbuTextInputControllerProps,
  IWbuTextInputStateValues,
} from '../../../components/WbuTextInput/WbuTextInput.props';

export default withCompController<
  IWbuTextInputMapperProps,
  IWbuTextInputControllerProps,
  IWbuTextInputProps,
  IWbuTextInputStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
