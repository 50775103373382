import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      contentOutline: 'shadow',
      backgroundColor: '#FFC233',
      backgroundFill: 'neutral',
    },
    ...compData,
  };
});

const css = withCompInfo<any, any>()(['compLayout'], ({ compLayout }) => {
  return {
    ...compLayout,
  };
});

export default createComponentMapperModel({
  props,
  css,
});
