import * as React from 'react';
import TextButton, {
  TextButtonSize,
  TextButtonSkin,
  TextButtonWeight,
  TextButtonUnderline,
} from 'wix-style-react/dist/src/TextButton';
import { getDataAttributes } from '../../../common/utils';
import { usePlatformIcon, usePlatformLink } from '../../../providers';
import {
  IWsrTextButtonProps,
  SIZES,
  SKINS,
  UNDERLINES,
  WEIGHTS,
} from '../WsrTextButton.props';

const WsrTextButton: React.FC<IWsrTextButtonProps> = props => {
  const {
    id,
    className,
    value,
    link,
    prefixIcon,
    suffixIcon,
    disabled,
    onClick = () => {},
    onMouseEnter,
    onMouseLeave,
    ...rest
  } = props;

  const linkProps = usePlatformLink(link);
  const platformPrefixIcon = usePlatformIcon(prefixIcon);
  const platformSuffixIcon = usePlatformIcon(suffixIcon);

  const size = Object.keys(SIZES).find(
    key => SIZES[key] === rest.size,
  ) as TextButtonSize;
  const skin = Object.keys(SKINS).find(
    key => SKINS[key] === rest.skin,
  ) as TextButtonSkin;
  const weight = Object.keys(WEIGHTS).find(
    key => WEIGHTS[key] === rest.weight,
  ) as TextButtonWeight;
  const underline = Object.keys(UNDERLINES).find(
    key => UNDERLINES[key] === rest.underline,
  ) as TextButtonUnderline;

  return (
    <div id={id} {...getDataAttributes(props)} className={className}>
      <TextButton
        {...linkProps}
        as={linkProps.href ? 'a' : undefined}
        skin={skin}
        size={size}
        onClick={onClick}
        prefixIcon={platformPrefixIcon}
        suffixIcon={platformSuffixIcon}
        disabled={disabled}
        underline={underline}
        weight={weight}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {value}
      </TextButton>
    </div>
  );
};

export default WsrTextButton;
