import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWsrTableListItemProps,
  IWsrTableListItemMapperProps,
  IWsrTableListItemControllerProps,
  IWsrTableListItemStateValues,
} from '../../../components/WsrTableListItem/WsrTableListItem.props';

export default withCompController<
  IWsrTableListItemMapperProps,
  IWsrTableListItemControllerProps,
  IWsrTableListItemProps,
  IWsrTableListItemStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
