import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWsrTextProps,
  IWsrTextMapperProps,
  IWsrTextControllerProps,
  IWsrTextStateValues,
} from '../../../components/WsrText/WsrText.props';

export default withCompController<
  IWsrTextMapperProps,
  IWsrTextControllerProps,
  IWsrTextProps,
  IWsrTextStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
