import ControlTypesComponent from '../components/ControlTypes/viewer/ControlTypes';
import ControlTypesMapper from '../.components/ControlTypes/viewer/ControlTypes.mapper';
import ControlTypesController from '../.components/ControlTypes/viewer/ControlTypes.controller';
import SettingsPanelContainerComponent from '../components/SettingsPanelContainer/viewer/SettingsPanelContainer';
import SettingsPanelContainerMapper from '../.components/SettingsPanelContainer/viewer/SettingsPanelContainer.mapper';
import SettingsPanelContainerController from '../.components/SettingsPanelContainer/viewer/SettingsPanelContainer.controller';
import WbuAccordionComponent from '../components/WbuAccordion/viewer/WbuAccordion';
import WbuAccordionMapper from '../.components/WbuAccordion/viewer/WbuAccordion.mapper';
import WbuAccordionController from '../.components/WbuAccordion/viewer/WbuAccordion.controller';
import WbuBackButtonComponent from '../components/WbuBackButton/viewer/WbuBackButton';
import WbuBackButtonMapper from '../.components/WbuBackButton/viewer/WbuBackButton.mapper';
import WbuBackButtonController from '../.components/WbuBackButton/viewer/WbuBackButton.controller';
import WbuButtonComponent from '../components/WbuButton/viewer/WbuButton';
import WbuButtonMapper from '../.components/WbuButton/viewer/WbuButton.mapper';
import WbuButtonController from '../.components/WbuButton/viewer/WbuButton.controller';
import WbuCheckboxGroupComponent from '../components/WbuCheckboxGroup/viewer/WbuCheckboxGroup';
import WbuCheckboxGroupMapper from '../.components/WbuCheckboxGroup/viewer/WbuCheckboxGroup.mapper';
import WbuCheckboxGroupController from '../.components/WbuCheckboxGroup/viewer/WbuCheckboxGroup.controller';
import WbuColorPickerComponent from '../components/WbuColorPicker/viewer/WbuColorPicker';
import WbuColorPickerMapper from '../.components/WbuColorPicker/viewer/WbuColorPicker.mapper';
import WbuColorPickerController from '../.components/WbuColorPicker/viewer/WbuColorPicker.controller';
import WbuColorSelectorComponent from '../components/WbuColorSelector/viewer/WbuColorSelector';
import WbuColorSelectorMapper from '../.components/WbuColorSelector/viewer/WbuColorSelector.mapper';
import WbuColorSelectorController from '../.components/WbuColorSelector/viewer/WbuColorSelector.controller';
import WbuColumnControllerControlComponent from '../components/WbuColumnControllerControl/viewer/WbuColumnControllerControl';
import WbuColumnControllerControlMapper from '../components/WbuColumnControllerControl/viewer/WbuColumnControllerControl.mapper';
import WbuColumnControllerControlController from '../.components/WbuColumnControllerControl/viewer/WbuColumnControllerControl.controller';
import WbuCornerRadiusComponent from '../components/WbuCornerRadius/viewer/WbuCornerRadius';
import WbuCornerRadiusMapper from '../.components/WbuCornerRadius/viewer/WbuCornerRadius.mapper';
import WbuCornerRadiusController from '../.components/WbuCornerRadius/viewer/WbuCornerRadius.controller';
import WbuDatePickerComponent from '../components/WbuDatePicker/viewer/WbuDatePicker';
import WbuDatePickerMapper from '../.components/WbuDatePicker/viewer/WbuDatePicker.mapper';
import WbuDatePickerController from '../.components/WbuDatePicker/viewer/WbuDatePicker.controller';
import WbuDrillDownComponent from '../components/WbuDrillDown/viewer/WbuDrillDown';
import WbuDrillDownMapper from '../.components/WbuDrillDown/viewer/WbuDrillDown.mapper';
import WbuDrillDownController from '../.components/WbuDrillDown/viewer/WbuDrillDown.controller';
import WbuDropdownComponent from '../components/WbuDropdown/viewer/WbuDropdown';
import WbuDropdownMapper from '../.components/WbuDropdown/viewer/WbuDropdown.mapper';
import WbuDropdownController from '../.components/WbuDropdown/viewer/WbuDropdown.controller';
import WbuEmptyStateComponent from '../components/WbuEmptyState/viewer/WbuEmptyState';
import WbuEmptyStateMapper from '../.components/WbuEmptyState/viewer/WbuEmptyState.mapper';
import WbuEmptyStateController from '../.components/WbuEmptyState/viewer/WbuEmptyState.controller';
import WbuFontAndColorPickerComponent from '../components/WbuFontAndColorPicker/viewer/WbuFontAndColorPicker';
import WbuFontAndColorPickerMapper from '../.components/WbuFontAndColorPicker/viewer/WbuFontAndColorPicker.mapper';
import WbuFontAndColorPickerController from '../.components/WbuFontAndColorPicker/viewer/WbuFontAndColorPicker.controller';
import WbuHorizontalTabsComponent from '../components/WbuHorizontalTabs/viewer/WbuHorizontalTabs';
import WbuHorizontalTabsMapper from '../.components/WbuHorizontalTabs/viewer/WbuHorizontalTabs.mapper';
import WbuHorizontalTabsController from '../.components/WbuHorizontalTabs/viewer/WbuHorizontalTabs.controller';
import WbuLeftTextButtonComponent from '../components/WbuLeftTextButton/viewer/WbuLeftTextButton';
import WbuLeftTextButtonMapper from '../.components/WbuLeftTextButton/viewer/WbuLeftTextButton.mapper';
import WbuLeftTextButtonController from '../.components/WbuLeftTextButton/viewer/WbuLeftTextButton.controller';
import WbuMultilineTextInputComponent from '../components/WbuMultilineTextInput/viewer/WbuMultilineTextInput';
import WbuMultilineTextInputPreviewMapper from '../components/WbuMultilineTextInput/viewer/WbuMultilineTextInput.previewMapper';
import WbuMultilineTextInputMapper from '../components/WbuMultilineTextInput/viewer/WbuMultilineTextInput.mapper';
import WbuMultilineTextInputController from '../.components/WbuMultilineTextInput/viewer/WbuMultilineTextInput.controller';
import WbuNotificationBannerComponent from '../components/WbuNotificationBanner/viewer/WbuNotificationBanner';
import WbuNotificationBannerMapper from '../.components/WbuNotificationBanner/viewer/WbuNotificationBanner.mapper';
import WbuNotificationBannerController from '../.components/WbuNotificationBanner/viewer/WbuNotificationBanner.controller';
import WbuNumericInputComponent from '../components/WbuNumericInput/viewer/WbuNumericInput';
import WbuNumericInputMapper from '../.components/WbuNumericInput/viewer/WbuNumericInput.mapper';
import WbuNumericInputController from '../.components/WbuNumericInput/viewer/WbuNumericInput.controller';
import WbuRadioButtonGroupComponent from '../components/WbuRadioButtonGroup/viewer/WbuRadioButtonGroup';
import WbuRadioButtonGroupMapper from '../.components/WbuRadioButtonGroup/viewer/WbuRadioButtonGroup.mapper';
import WbuRadioButtonGroupController from '../.components/WbuRadioButtonGroup/viewer/WbuRadioButtonGroup.controller';
import WbuRichTextComponent from '../components/WbuRichText/viewer/WbuRichText';
import WbuRichTextMapper from '../.components/WbuRichText/viewer/WbuRichText.mapper';
import WbuRichTextController from '../.components/WbuRichText/viewer/WbuRichText.controller';
import WbuSecondaryMediaComponent from '../components/WbuSecondaryMedia/viewer/WbuSecondaryMedia';
import WbuSecondaryMediaMapper from '../.components/WbuSecondaryMedia/viewer/WbuSecondaryMedia.mapper';
import WbuSecondaryMediaController from '../.components/WbuSecondaryMedia/viewer/WbuSecondaryMedia.controller';
import WbuSectionDividerComponent from '../components/WbuSectionDivider/viewer/WbuSectionDivider';
import WbuSectionDividerMapper from '../.components/WbuSectionDivider/viewer/WbuSectionDivider.mapper';
import WbuSectionDividerController from '../.components/WbuSectionDivider/viewer/WbuSectionDivider.controller';
import WbuSliderComponent from '../components/WbuSlider/viewer/WbuSlider';
import WbuSliderMapper from '../.components/WbuSlider/viewer/WbuSlider.mapper';
import WbuSliderController from '../.components/WbuSlider/viewer/WbuSlider.controller';
import WbuTextInputComponent from '../components/WbuTextInput/viewer/WbuTextInput';
import WbuTextInputPreviewMapper from '../components/WbuTextInput/viewer/WbuTextInput.previewMapper';
import WbuTextInputMapper from '../components/WbuTextInput/viewer/WbuTextInput.mapper';
import WbuTextInputController from '../.components/WbuTextInput/viewer/WbuTextInput.controller';
import WbuThumbnailsComponent from '../components/WbuThumbnails/viewer/WbuThumbnails';
import WbuThumbnailsMapper from '../components/WbuThumbnails/viewer/WbuThumbnails.mapper';
import WbuThumbnailsController from '../.components/WbuThumbnails/viewer/WbuThumbnails.controller';
import WbuToggleSwitchComponent from '../components/WbuToggleSwitch/viewer/WbuToggleSwitch';
import WbuToggleSwitchMapper from '../.components/WbuToggleSwitch/viewer/WbuToggleSwitch.mapper';
import WbuToggleSwitchController from '../.components/WbuToggleSwitch/viewer/WbuToggleSwitch.controller';
import WsrButtonComponent from '../components/WsrButton/viewer/WsrButton';
import WsrButtonMapper from '../components/WsrButton/viewer/WsrButton.mapper';
import WsrButtonController from '../.components/WsrButton/viewer/WsrButton.controller';
import WsrCardComponent from '../components/WsrCard/viewer/WsrCard';
import WsrCardPreviewMapper from '../components/WsrCard/viewer/WsrCard.previewMapper';
import WsrCardMapper from '../components/WsrCard/viewer/WsrCard.mapper';
import WsrCardController from '../.components/WsrCard/viewer/WsrCard.controller';
import WsrCheckboxComponent from '../components/WsrCheckbox/viewer/WsrCheckbox';
import WsrCheckboxMapper from '../components/WsrCheckbox/viewer/WsrCheckbox.mapper';
import WsrCheckboxController from '../.components/WsrCheckbox/viewer/WsrCheckbox.controller';
import WsrCustomModalLayoutComponent from '../components/WsrCustomModalLayout/viewer/WsrCustomModalLayout';
import WsrCustomModalLayoutPreviewMapper from '../components/WsrCustomModalLayout/viewer/WsrCustomModalLayout.previewMapper';
import WsrCustomModalLayoutMapper from '../components/WsrCustomModalLayout/viewer/WsrCustomModalLayout.mapper';
import WsrCustomModalLayoutController from '../.components/WsrCustomModalLayout/viewer/WsrCustomModalLayout.controller';
import WsrDatePickerComponent from '../components/WsrDatePicker/viewer/WsrDatePicker';
import WsrDatePickerMapper from '../components/WsrDatePicker/viewer/WsrDatePicker.mapper';
import WsrDatePickerController from '../.components/WsrDatePicker/viewer/WsrDatePicker.controller';
import WsrDividerComponent from '../components/WsrDivider/viewer/WsrDivider';
import WsrDividerMapper from '../components/WsrDivider/viewer/WsrDivider.mapper';
import WsrDividerController from '../.components/WsrDivider/viewer/WsrDivider.controller';
import WsrDropdownComponent from '../components/WsrDropdown/viewer/WsrDropdown';
import WsrDropdownMapper from '../components/WsrDropdown/viewer/WsrDropdown.mapper';
import WsrDropdownController from '../.components/WsrDropdown/viewer/WsrDropdown.controller';
import WsrHeadingComponent from '../components/WsrHeading/viewer/WsrHeading';
import WsrHeadingMapper from '../components/WsrHeading/viewer/WsrHeading.mapper';
import WsrHeadingController from '../.components/WsrHeading/viewer/WsrHeading.controller';
import WsrIconButtonComponent from '../components/WsrIconButton/viewer/WsrIconButton';
import WsrIconButtonMapper from '../components/WsrIconButton/viewer/WsrIconButton.mapper';
import WsrIconButtonController from '../.components/WsrIconButton/viewer/WsrIconButton.controller';
import WsrInfoIconComponent from '../components/WsrInfoIcon/viewer/WsrInfoIcon';
import WsrInfoIconMapper from '../components/WsrInfoIcon/viewer/WsrInfoIcon.mapper';
import WsrInfoIconController from '../.components/WsrInfoIcon/viewer/WsrInfoIcon.controller';
import WsrInputComponent from '../components/WsrInput/viewer/WsrInput';
import WsrInputMapper from '../components/WsrInput/viewer/WsrInput.mapper';
import WsrInputController from '../.components/WsrInput/viewer/WsrInput.controller';
import WsrMessageModalLayoutComponent from '../components/WsrMessageModalLayout/viewer/WsrMessageModalLayout';
import WsrMessageModalLayoutMapper from '../components/WsrMessageModalLayout/viewer/WsrMessageModalLayout.mapper';
import WsrMessageModalLayoutController from '../.components/WsrMessageModalLayout/viewer/WsrMessageModalLayout.controller';
import WsrNumberInputComponent from '../components/WsrNumberInput/viewer/WsrNumberInput';
import WsrNumberInputMapper from '../components/WsrNumberInput/viewer/WsrNumberInput.mapper';
import WsrNumberInputController from '../.components/WsrNumberInput/viewer/WsrNumberInput.controller';
import WsrPageComponent from '../components/WsrPage/viewer/WsrPage';
import WsrPagePreviewMapper from '../components/WsrPage/viewer/WsrPage.previewMapper';
import WsrPageMapper from '../components/WsrPage/viewer/WsrPage.mapper';
import WsrPageController from '../.components/WsrPage/viewer/WsrPage.controller';
import WsrPreviewWidgetComponent from '../components/WsrPreviewWidget/viewer/WsrPreviewWidget';
import WsrPreviewWidgetPreviewMapper from '../components/WsrPreviewWidget/viewer/WsrPreviewWidget.previewMapper';
import WsrPreviewWidgetMapper from '../components/WsrPreviewWidget/viewer/WsrPreviewWidget.mapper';
import WsrPreviewWidgetController from '../.components/WsrPreviewWidget/viewer/WsrPreviewWidget.controller';
import WsrSelectableAccordionComponent from '../components/WsrSelectableAccordion/viewer/WsrSelectableAccordion';
import WsrSelectableAccordionPreviewMapper from '../components/WsrSelectableAccordion/viewer/WsrSelectableAccordion.previewMapper';
import WsrSelectableAccordionMapper from '../.components/WsrSelectableAccordion/viewer/WsrSelectableAccordion.mapper';
import WsrSelectableAccordionController from '../.components/WsrSelectableAccordion/viewer/WsrSelectableAccordion.controller';
import WsrTableListItemComponent from '../components/WsrTableListItem/viewer/WsrTableListItem';
import WsrTableListItemPreviewMapper from '../components/WsrTableListItem/viewer/WsrTableListItem.previewMapper';
import WsrTableListItemMapper from '../components/WsrTableListItem/viewer/WsrTableListItem.mapper';
import WsrTableListItemController from '../.components/WsrTableListItem/viewer/WsrTableListItem.controller';
import WsrTextComponent from '../components/WsrText/viewer/WsrText';
import WsrTextMapper from '../components/WsrText/viewer/WsrText.mapper';
import WsrTextController from '../.components/WsrText/viewer/WsrText.controller';
import WsrTextAreaComponent from '../components/WsrTextArea/viewer/WsrTextArea';
import WsrTextAreaMapper from '../components/WsrTextArea/viewer/WsrTextArea.mapper';
import WsrTextAreaController from '../.components/WsrTextArea/viewer/WsrTextArea.controller';
import WsrTextButtonComponent from '../components/WsrTextButton/viewer/WsrTextButton';
import WsrTextButtonMapper from '../components/WsrTextButton/viewer/WsrTextButton.mapper';
import WsrTextButtonController from '../.components/WsrTextButton/viewer/WsrTextButton.controller';
import WsrToggleSwitchComponent from '../components/WsrToggleSwitch/viewer/WsrToggleSwitch';
import WsrToggleSwitchPreviewMapper from '../components/WsrToggleSwitch/viewer/WsrToggleSwitch.previewMapper';
import WsrToggleSwitchMapper from '../components/WsrToggleSwitch/viewer/WsrToggleSwitch.mapper';
import WsrToggleSwitchController from '../.components/WsrToggleSwitch/viewer/WsrToggleSwitch.controller';
import WutAvatarComponent from '../components/WutAvatar/viewer/WutAvatar';
import WutAvatarPreviewMapper from '../components/WutAvatar/viewer/WutAvatar.previewMapper';
import WutAvatarMapper from '../components/WutAvatar/viewer/WutAvatar.mapper';
import WutAvatarController from '../.components/WutAvatar/viewer/WutAvatar.controller';
import WutAvatarGroupComponent from '../components/WutAvatarGroup/viewer/WutAvatarGroup';
import WutAvatarGroupPreviewMapper from '../components/WutAvatarGroup/viewer/WutAvatarGroup.previewMapper';
import WutAvatarGroupMapper from '../components/WutAvatarGroup/viewer/WutAvatarGroup.mapper';
import WutAvatarGroupController from '../.components/WutAvatarGroup/viewer/WutAvatarGroup.controller';
import WutColorPickerComponent from '../components/WutColorPicker/viewer/WutColorPicker';
import WutColorPickerMapper from '../.components/WutColorPicker/viewer/WutColorPicker.mapper';
import WutColorPickerController from '../.components/WutColorPicker/viewer/WutColorPicker.controller';
import WutRadioButtonComponent from '../components/WutRadioButton/viewer/WutRadioButton';
import WutRadioButtonPreviewMapper from '../components/WutRadioButton/viewer/WutRadioButton.previewMapper';
import WutRadioButtonMapper from '../components/WutRadioButton/viewer/WutRadioButton.mapper';
import WutRadioButtonController from '../.components/WutRadioButton/viewer/WutRadioButton.controller';
import WutTabbedContentComponent from '../components/WutTabbedContent/viewer/WutTabbedContent';
import WutTabbedContentMapper from '../.components/WutTabbedContent/viewer/WutTabbedContent.mapper';
import WutTabbedContentController from '../.components/WutTabbedContent/viewer/WutTabbedContent.controller';
import { createPreviewFallback } from '@wix/editor-elements-preview-utils';


const ControlTypes = {
  component: ControlTypesComponent,
  mapper: ControlTypesMapper,
  controller: ControlTypesController
};

const SettingsPanelContainer = {
  component: SettingsPanelContainerComponent,
  mapper: SettingsPanelContainerMapper,
  controller: SettingsPanelContainerController
};

const WbuAccordion = {
  component: WbuAccordionComponent,
  mapper: WbuAccordionMapper,
  controller: WbuAccordionController
};

const WbuBackButton = {
  component: WbuBackButtonComponent,
  mapper: WbuBackButtonMapper,
  controller: WbuBackButtonController
};

const WbuButton = {
  component: WbuButtonComponent,
  mapper: WbuButtonMapper,
  controller: WbuButtonController
};

const WbuCheckboxGroup = {
  component: WbuCheckboxGroupComponent,
  mapper: WbuCheckboxGroupMapper,
  controller: WbuCheckboxGroupController
};

const WbuColorPicker = {
  component: WbuColorPickerComponent,
  mapper: WbuColorPickerMapper,
  controller: WbuColorPickerController
};

const WbuColorSelector = {
  component: WbuColorSelectorComponent,
  mapper: WbuColorSelectorMapper,
  controller: WbuColorSelectorController
};

const WbuColumnControllerControl = {
  component: WbuColumnControllerControlComponent,
  mapper: WbuColumnControllerControlMapper,
  controller: WbuColumnControllerControlController
};

const WbuCornerRadius = {
  component: WbuCornerRadiusComponent,
  mapper: WbuCornerRadiusMapper,
  controller: WbuCornerRadiusController
};

const WbuDatePicker = {
  component: WbuDatePickerComponent,
  mapper: WbuDatePickerMapper,
  controller: WbuDatePickerController
};

const WbuDrillDown = {
  component: WbuDrillDownComponent,
  mapper: WbuDrillDownMapper,
  controller: WbuDrillDownController
};

const WbuDropdown = {
  component: WbuDropdownComponent,
  mapper: WbuDropdownMapper,
  controller: WbuDropdownController
};

const WbuEmptyState = {
  component: WbuEmptyStateComponent,
  mapper: WbuEmptyStateMapper,
  controller: WbuEmptyStateController
};

const WbuFontAndColorPicker = {
  component: WbuFontAndColorPickerComponent,
  mapper: WbuFontAndColorPickerMapper,
  controller: WbuFontAndColorPickerController
};

const WbuHorizontalTabs = {
  component: WbuHorizontalTabsComponent,
  mapper: WbuHorizontalTabsMapper,
  controller: WbuHorizontalTabsController
};

const WbuLeftTextButton = {
  component: WbuLeftTextButtonComponent,
  mapper: WbuLeftTextButtonMapper,
  controller: WbuLeftTextButtonController
};

const WbuMultilineTextInput = {
  component: createPreviewFallback(WbuMultilineTextInputComponent),
  previewMapper: WbuMultilineTextInputPreviewMapper,
  mapper: WbuMultilineTextInputMapper,
  controller: WbuMultilineTextInputController
};

const WbuNotificationBanner = {
  component: WbuNotificationBannerComponent,
  mapper: WbuNotificationBannerMapper,
  controller: WbuNotificationBannerController
};

const WbuNumericInput = {
  component: WbuNumericInputComponent,
  mapper: WbuNumericInputMapper,
  controller: WbuNumericInputController
};

const WbuRadioButtonGroup = {
  component: WbuRadioButtonGroupComponent,
  mapper: WbuRadioButtonGroupMapper,
  controller: WbuRadioButtonGroupController
};

const WbuRichText = {
  component: WbuRichTextComponent,
  mapper: WbuRichTextMapper,
  controller: WbuRichTextController
};

const WbuSecondaryMedia = {
  component: WbuSecondaryMediaComponent,
  mapper: WbuSecondaryMediaMapper,
  controller: WbuSecondaryMediaController
};

const WbuSectionDivider = {
  component: WbuSectionDividerComponent,
  mapper: WbuSectionDividerMapper,
  controller: WbuSectionDividerController
};

const WbuSlider = {
  component: WbuSliderComponent,
  mapper: WbuSliderMapper,
  controller: WbuSliderController
};

const WbuTextInput = {
  component: createPreviewFallback(WbuTextInputComponent),
  previewMapper: WbuTextInputPreviewMapper,
  mapper: WbuTextInputMapper,
  controller: WbuTextInputController
};

const WbuThumbnails = {
  component: WbuThumbnailsComponent,
  mapper: WbuThumbnailsMapper,
  controller: WbuThumbnailsController
};

const WbuToggleSwitch = {
  component: WbuToggleSwitchComponent,
  mapper: WbuToggleSwitchMapper,
  controller: WbuToggleSwitchController
};

const WsrButton = {
  component: WsrButtonComponent,
  mapper: WsrButtonMapper,
  controller: WsrButtonController
};

const WsrCard = {
  component: createPreviewFallback(WsrCardComponent),
  previewMapper: WsrCardPreviewMapper,
  mapper: WsrCardMapper,
  controller: WsrCardController
};

const WsrCheckbox = {
  component: WsrCheckboxComponent,
  mapper: WsrCheckboxMapper,
  controller: WsrCheckboxController
};

const WsrCustomModalLayout = {
  component: createPreviewFallback(WsrCustomModalLayoutComponent),
  previewMapper: WsrCustomModalLayoutPreviewMapper,
  mapper: WsrCustomModalLayoutMapper,
  controller: WsrCustomModalLayoutController
};

const WsrDatePicker = {
  component: WsrDatePickerComponent,
  mapper: WsrDatePickerMapper,
  controller: WsrDatePickerController
};

const WsrDivider = {
  component: WsrDividerComponent,
  mapper: WsrDividerMapper,
  controller: WsrDividerController
};

const WsrDropdown = {
  component: WsrDropdownComponent,
  mapper: WsrDropdownMapper,
  controller: WsrDropdownController
};

const WsrHeading = {
  component: WsrHeadingComponent,
  mapper: WsrHeadingMapper,
  controller: WsrHeadingController
};

const WsrIconButton = {
  component: WsrIconButtonComponent,
  mapper: WsrIconButtonMapper,
  controller: WsrIconButtonController
};

const WsrInfoIcon = {
  component: WsrInfoIconComponent,
  mapper: WsrInfoIconMapper,
  controller: WsrInfoIconController
};

const WsrInput = {
  component: WsrInputComponent,
  mapper: WsrInputMapper,
  controller: WsrInputController
};

const WsrMessageModalLayout = {
  component: WsrMessageModalLayoutComponent,
  mapper: WsrMessageModalLayoutMapper,
  controller: WsrMessageModalLayoutController
};

const WsrNumberInput = {
  component: WsrNumberInputComponent,
  mapper: WsrNumberInputMapper,
  controller: WsrNumberInputController
};

const WsrPage = {
  component: createPreviewFallback(WsrPageComponent),
  previewMapper: WsrPagePreviewMapper,
  mapper: WsrPageMapper,
  controller: WsrPageController
};

const WsrPreviewWidget = {
  component: createPreviewFallback(WsrPreviewWidgetComponent),
  previewMapper: WsrPreviewWidgetPreviewMapper,
  mapper: WsrPreviewWidgetMapper,
  controller: WsrPreviewWidgetController
};

const WsrSelectableAccordion = {
  component: createPreviewFallback(WsrSelectableAccordionComponent),
  previewMapper: WsrSelectableAccordionPreviewMapper,
  mapper: WsrSelectableAccordionMapper,
  controller: WsrSelectableAccordionController
};

const WsrTableListItem = {
  component: createPreviewFallback(WsrTableListItemComponent),
  previewMapper: WsrTableListItemPreviewMapper,
  mapper: WsrTableListItemMapper,
  controller: WsrTableListItemController
};

const WsrText = {
  component: WsrTextComponent,
  mapper: WsrTextMapper,
  controller: WsrTextController
};

const WsrTextArea = {
  component: WsrTextAreaComponent,
  mapper: WsrTextAreaMapper,
  controller: WsrTextAreaController
};

const WsrTextButton = {
  component: WsrTextButtonComponent,
  mapper: WsrTextButtonMapper,
  controller: WsrTextButtonController
};

const WsrToggleSwitch = {
  component: createPreviewFallback(WsrToggleSwitchComponent),
  previewMapper: WsrToggleSwitchPreviewMapper,
  mapper: WsrToggleSwitchMapper,
  controller: WsrToggleSwitchController
};

const WutAvatar = {
  component: createPreviewFallback(WutAvatarComponent),
  previewMapper: WutAvatarPreviewMapper,
  mapper: WutAvatarMapper,
  controller: WutAvatarController
};

const WutAvatarGroup = {
  component: createPreviewFallback(WutAvatarGroupComponent),
  previewMapper: WutAvatarGroupPreviewMapper,
  mapper: WutAvatarGroupMapper,
  controller: WutAvatarGroupController
};

const WutColorPicker = {
  component: WutColorPickerComponent,
  mapper: WutColorPickerMapper,
  controller: WutColorPickerController
};

const WutRadioButton = {
  component: createPreviewFallback(WutRadioButtonComponent),
  previewMapper: WutRadioButtonPreviewMapper,
  mapper: WutRadioButtonMapper,
  controller: WutRadioButtonController
};

const WutTabbedContent = {
  component: WutTabbedContentComponent,
  mapper: WutTabbedContentMapper,
  controller: WutTabbedContentController
};


export const components = {
  ['ControlTypes']: ControlTypes,
  ['SettingsPanelContainer']: SettingsPanelContainer,
  ['WbuAccordion']: WbuAccordion,
  ['WbuBackButton']: WbuBackButton,
  ['WbuButton']: WbuButton,
  ['WbuCheckboxGroup']: WbuCheckboxGroup,
  ['WbuColorPicker']: WbuColorPicker,
  ['WbuColorSelector']: WbuColorSelector,
  ['WbuColumnControllerControl']: WbuColumnControllerControl,
  ['WbuCornerRadius']: WbuCornerRadius,
  ['WbuDatePicker']: WbuDatePicker,
  ['WbuDrillDown']: WbuDrillDown,
  ['WbuDropdown']: WbuDropdown,
  ['WbuEmptyState']: WbuEmptyState,
  ['WbuFontAndColorPicker']: WbuFontAndColorPicker,
  ['WbuHorizontalTabs']: WbuHorizontalTabs,
  ['WbuLeftTextButton']: WbuLeftTextButton,
  ['WbuMultilineTextInput']: WbuMultilineTextInput,
  ['WbuNotificationBanner']: WbuNotificationBanner,
  ['WbuNumericInput']: WbuNumericInput,
  ['WbuRadioButtonGroup']: WbuRadioButtonGroup,
  ['WbuRichText']: WbuRichText,
  ['WbuSecondaryMedia']: WbuSecondaryMedia,
  ['WbuSectionDivider']: WbuSectionDivider,
  ['WbuSlider']: WbuSlider,
  ['WbuTextInput']: WbuTextInput,
  ['WbuThumbnails']: WbuThumbnails,
  ['WbuToggleSwitch']: WbuToggleSwitch,
  ['WsrButton']: WsrButton,
  ['WsrCard']: WsrCard,
  ['WsrCheckbox']: WsrCheckbox,
  ['WsrCustomModalLayout']: WsrCustomModalLayout,
  ['WsrDatePicker']: WsrDatePicker,
  ['WsrDivider']: WsrDivider,
  ['WsrDropdown']: WsrDropdown,
  ['WsrHeading']: WsrHeading,
  ['WsrIconButton']: WsrIconButton,
  ['WsrInfoIcon']: WsrInfoIcon,
  ['WsrInput']: WsrInput,
  ['WsrMessageModalLayout']: WsrMessageModalLayout,
  ['WsrNumberInput']: WsrNumberInput,
  ['WsrPage']: WsrPage,
  ['WsrPreviewWidget']: WsrPreviewWidget,
  ['WsrSelectableAccordion']: WsrSelectableAccordion,
  ['WsrTableListItem']: WsrTableListItem,
  ['WsrText']: WsrText,
  ['WsrTextArea']: WsrTextArea,
  ['WsrTextButton']: WsrTextButton,
  ['WsrToggleSwitch']: WsrToggleSwitch,
  ['WutAvatar']: WutAvatar,
  ['WutAvatarGroup']: WutAvatarGroup,
  ['WutColorPicker']: WutColorPicker,
  ['WutRadioButton']: WutRadioButton,
  ['WutTabbedContent']: WutTabbedContent
};

