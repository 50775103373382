
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "WsrText2228909155",
      {"classes":{"root":"WsrText2228909155__root"},"vars":{"width":"--width"},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  