import * as React from 'react';

import { ColumnControllerControl } from '@wix/wix-base-ui/next';
import { IconButton } from '@wix/wix-base-ui/lib/esm';
import {
  Delete,
  Add,
  Duplicate,
} from '@wix/wix-ui-icons-common/classic-editor';
import './WbuColumnControllerControl.global.scss';

import type { IWbuColumnControllerControlProps } from '../WbuColumnControllerControl.props';

const WbuColumnControllerControl: React.FC<
  IWbuColumnControllerControlProps
> = props => {
  const {
    id,
    className,
    fieldTitle,
    tooltipMessage,
    enableReorder,
    enableActions,
    minimumValue,
    dividerType,
  } = props;

  const [columns, setColumn] = React.useState([
    { id: '2_1', value: 50 },
    { id: '2_2', value: 50 },
  ]);

  const divider =
    dividerType === 'None'
      ? undefined
      : (dividerType.toLowerCase() as 'short' | 'long');

  const tooltipInfo =
    tooltipMessage !== ''
      ? {
          text: tooltipMessage,
        }
      : undefined;

  return (
    <div id={id} className={className}>
      <ColumnControllerControl
        allowReorder={enableReorder}
        headerProps={{
          title: fieldTitle,
          tooltipInfo,
        }}
        columns={columns}
        onChange={({ newColumns }) => setColumn(newColumns)}
        minValue={minimumValue}
        divider={divider}
        removeButtonNode={
          <IconButton size="medium" skin="light">
            <Delete />
          </IconButton>
        }
        addButtonNode={
          <IconButton size="medium" skin="light">
            <Add />
          </IconButton>
        }
        duplicateButtonNode={
          <IconButton size="medium" skin="light">
            <Duplicate />
          </IconButton>
        }
        allowActions={enableActions}
      />
    </div>
  );
};

export default WbuColumnControllerControl;
