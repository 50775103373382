
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "WsrToggleSwitch52923237",
      {"classes":{"root":"WsrToggleSwitch52923237__root","toggleSwitchWrapper":"WsrToggleSwitch52923237__toggleSwitchWrapper"},"vars":{},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  