import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWsrCheckboxProps,
  IWsrCheckboxMapperProps,
  IWsrCheckboxControllerProps,
  IWsrCheckboxStateValues,
} from '../../../components/WsrCheckbox/WsrCheckbox.props';

export default withCompController<
  IWsrCheckboxMapperProps,
  IWsrCheckboxControllerProps,
  IWsrCheckboxProps,
  IWsrCheckboxStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
