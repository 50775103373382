import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      suffix: {},
      subheaderSkin: 'standard',
      subheaderTitle: 'Subheader title',
      showSubheader: false,
      headerSubtitle: 'Card subtitle',
      headerTitle: 'Card title',
      showHeader: true,
    },
    ...compData,
  };
});

const css = withCompInfo<any, any>()(['compLayout'], ({ compLayout }) => {
  return {
    ...compLayout,
  };
});

export default createComponentMapperModel({
  props,
  css,
});
