import { withCompController } from '@wix/editor-elements-integrations';
import type {
  ISettingsPanelContainerProps,
  ISettingsPanelContainerMapperProps,
  ISettingsPanelContainerControllerProps,
  ISettingsPanelContainerStateValues,
} from '../../../components/SettingsPanelContainer/SettingsPanelContainer.props';

export default withCompController<
  ISettingsPanelContainerMapperProps,
  ISettingsPanelContainerControllerProps,
  ISettingsPanelContainerProps,
  ISettingsPanelContainerStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
