import * as React from 'react';

import TableListItem, {
  TableListItemProps,
} from 'wix-style-react/dist/src/TableListItem';
import { createSlotsContainer } from '@wix/editor-elements-integrations/thunderbolt';

import type { IWsrTableListItemProps } from '../WsrTableListItem.props';
import { getDataAttributes } from '../../../common/utils';
import { st, classes } from './WsrTableListItem.st.css';

const WsrTableListItem: React.FC<IWsrTableListItemProps> = props => {
  const { id, className, container, onItemClick } = props;
  const content = (
    <div className={st(classes.contentContainer)}>{container}</div>
  );

  const handleClick: TableListItemProps['onClick'] = () => {
    return onItemClick?.({ type: 'itemClick' });
  };

  const hasHoverEffect = onItemClick !== undefined;

  return (
    <div id={id} {...getDataAttributes(props)} className={className}>
      <TableListItem
        className={st(classes.root, { hasHoverEffect })}
        options={[{ value: content }]}
        onClick={hasHoverEffect ? handleClick : undefined}
      />
    </div>
  );
};

export default createSlotsContainer(WsrTableListItem);
