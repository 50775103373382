import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuThumbnailsProps,
  IWbuThumbnailsMapperProps,
  IWbuThumbnailsControllerProps,
  IWbuThumbnailsStateValues,
} from '../../../components/WbuThumbnails/WbuThumbnails.props';

export default withCompController<
  IWbuThumbnailsMapperProps,
  IWbuThumbnailsControllerProps,
  IWbuThumbnailsProps,
  IWbuThumbnailsStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
