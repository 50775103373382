import {
  defineLeanCompProps,
  InferExtendedPropsSchema,
} from '@wix/lean-components-infra';

const SIZES: { [key: string]: string } = {
  medium: 'Medium',
  small: 'Small',
};

const sizes = [...([...Object.values(SIZES)] as const)];

export const AREA_SKINS: { [key: string]: string } = {
  plain: 'Plain',
  filled: 'Filled',
  outlined: 'Outlined',
};

const areaSkins = [...([...Object.values(AREA_SKINS)] as const)];

export const SELECTION_AREAS: { [key: string]: string } = {
  always: 'Always',
  hover: 'On mouse hover',
};

const selectionAreas = [...([...Object.values(SELECTION_AREAS)] as const)];

const LABEL_ALIGNMENT: { [key: string]: string } = {
  center: 'Center',
  top: 'Top',
};

const labelAlignment = [...([...Object.values(LABEL_ALIGNMENT)] as const)];

const props = defineLeanCompProps(
  ({ schemaBuilder }) => ({
    label: schemaBuilder.shortString().default('Checkbox'),
    checked: schemaBuilder.boolean().default(false),
    showTooltip: schemaBuilder.boolean().default(false),
    tooltipContent: schemaBuilder.longString().default(''),
    disabled: schemaBuilder.boolean().default(false),
    required: schemaBuilder.boolean().default(false),
    size: schemaBuilder.stringEnum(sizes).default(SIZES.medium),
    selectionAreaSkin: schemaBuilder
      .stringEnum(areaSkins)
      .default(AREA_SKINS.plain),
    selectionArea: schemaBuilder
      .stringEnum(selectionAreas)
      .default(SELECTION_AREAS.always),
    vAlign: schemaBuilder
      .stringEnum(labelAlignment)
      .default(LABEL_ALIGNMENT.center),
  }),
  { dmPublish: 'draft' },
)
  .defineSdk(({ sdkMixin }) => ({
    mixins: [sdkMixin.changeable],
  }))
  .definePanels(({ settingsPanel, layoutPanel }) => {
    settingsPanel?.controls.omit('vAlign');

    layoutPanel?.controls.push('vAlign');

    settingsPanel?.controls.tooltipContent.isVisible(
      ({ props: { showTooltip } }) => showTooltip,
    );

    settingsPanel?.controls.selectionArea.isVisible(
      ({ props: { selectionAreaSkin } }) => selectionAreaSkin !== 'Plain',
    );
    settingsPanel?.controls.size.modify({
      controlType: 'radioButtons',
    });
    settingsPanel?.controls.selectionAreaSkin.modify({
      controlType: 'radioButtons',
    });
    settingsPanel?.controls.selectionArea.modify({
      controlType: 'radioButtons',
    });
    layoutPanel?.controls.vAlign.modify({
      controlType: 'radioButtons',
    });
  });

export type IWsrCheckboxProps = InferExtendedPropsSchema<typeof props> & {
  className?: string;
};

export default props;
