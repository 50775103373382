import {
  defineLeanCompProps,
  InferExtendedPropsSchema,
} from '@wix/lean-components-infra';

import { OnInvalidPayload } from './corvid/invalidPropsSDKFactory';
import { OnKeyPressPayload } from './corvid/keyPressPropsSDKFactory';
import { OnChangePayload } from './corvid/changePropsSDKFactory';

export const LABEL_POSITIONS: { [key: string]: string } = {
  top: 'On top',
  left: 'Before',
  right: 'After',
};
const labelPositions = [...([...Object.values(LABEL_POSITIONS)] as const)];

export const PREFIX_AND_SUFFIX_TYPES = {
  none: 'None',
  text: 'Text',
  icon: 'Icon',
};

const prefixAndSuffixTypeOptions = [
  ...([...Object.values(PREFIX_AND_SUFFIX_TYPES)] as const),
];
export type PrefixAndSuffixTypeOptions =
  (typeof prefixAndSuffixTypeOptions)[number];

export const ACTIVE_STATES: {
  [key: string]: {
    value: string;
    readOnly: boolean;
    disabled: boolean;
  };
} = {
  ACTIVE: {
    value: 'Active',
    readOnly: false,
    disabled: false,
  },
  READ_ONLY: {
    value: 'Read only',
    readOnly: true,
    disabled: false,
  },
  DISABLED: {
    value: 'Disabled',
    readOnly: false,
    disabled: true,
  },
};

const SIZES: { [key: string]: string } = {
  large: 'Large',
  medium: 'Medium',
  small: 'Small',
};

const sizes = [...([...Object.values(SIZES)] as const)];

export const BORDERS: { [key: string]: string } = {
  standard: 'Standard',
  round: 'Round',
  bottomLine: 'Underline only',
  none: 'None',
};

const borders = [...([...Object.values(BORDERS)] as const)];

const props = defineLeanCompProps(({ schemaBuilder }) => ({
  label: schemaBuilder.shortString().default('Number Input'),
  showTooltip: schemaBuilder.boolean().default(false),
  infoContent: schemaBuilder.longString().default(''),
  placeholderText: schemaBuilder.longString().default(''),
  value: schemaBuilder.integer().default(0),
  prefixType: schemaBuilder
    .stringEnum<PrefixAndSuffixTypeOptions>(prefixAndSuffixTypeOptions)
    .default(PREFIX_AND_SUFFIX_TYPES.none),
  prefixIcon: schemaBuilder.svg(),
  prefixValue: schemaBuilder.shortString().default(''),
  suffixType: schemaBuilder
    .stringEnum<PrefixAndSuffixTypeOptions>(prefixAndSuffixTypeOptions)
    .default(PREFIX_AND_SUFFIX_TYPES.none),
  suffixIcon: schemaBuilder.svg(),
  suffixValue: schemaBuilder.shortString().default(''),
  minAndMaxValues: schemaBuilder.boolean().default(false),
  minimumValue: schemaBuilder.integer().default(0),
  maximumValue: schemaBuilder.integer().default(500),
  validationMessage: schemaBuilder.longString().default('Invalid value'),
  stepSize: schemaBuilder.integer().default(1),
  required: schemaBuilder.boolean().default(false),
  size: schemaBuilder.stringEnum(sizes).default(SIZES.medium),
  border: schemaBuilder.stringEnum(borders).default(BORDERS.standard),
  labelPlacement: schemaBuilder
    .stringEnum(labelPositions)
    .default(LABEL_POSITIONS.top),
})).definePanels(({ settingsPanel, layoutPanel }) => {
  layoutPanel?.controls.push('labelPlacement');
  settingsPanel?.controls.omit('labelPlacement');
  settingsPanel?.controls.createSection(['showTooltip', 'infoContent']);
  settingsPanel?.controls.createSection(['placeholderText', 'value']);
  settingsPanel?.controls.createSection(
    ['prefixType', 'prefixIcon', 'prefixValue'],
    {
      name: 'dsgnsys_WsrNumberInput_prefix_section_label',
    },
  );
  settingsPanel?.controls.createSection(
    ['suffixType', 'suffixIcon', 'suffixValue'],
    {
      name: 'dsgnsys_WsrNumberInput_suffix_section_label',
    },
  );
  settingsPanel?.controls.createSection(
    [
      'minAndMaxValues',
      'minimumValue',
      'maximumValue',
      'validationMessage',
      'stepSize',
      'required',
    ],
    {
      name: 'dsgnsys_WsrNumberInput_rules_section_label',
    },
  );
  settingsPanel?.controls.createSection(['size'], {
    name: 'dsgnsys_WsrNumberInput_size_section_label',
  });
  settingsPanel?.controls.createSection(['border'], {
    name: 'dsgnsys_WsrNumberInput_style_section_label',
  });
  settingsPanel?.controls.infoContent.isVisible(
    ({ props: { showTooltip } }) => showTooltip,
  );
  settingsPanel?.controls.minimumValue.isVisible(
    ({ props: { minAndMaxValues } }) => minAndMaxValues,
  );
  settingsPanel?.controls.maximumValue.isVisible(
    ({ props: { minAndMaxValues } }) => minAndMaxValues,
  );
  settingsPanel?.controls.validationMessage.isVisible(
    ({ props: { minAndMaxValues } }) => minAndMaxValues,
  );
  settingsPanel?.controls.size.modify({
    controlType: 'radioButtons',
  });
  settingsPanel?.controls.prefixType.modify({
    controlType: 'radioButtons',
  });
  settingsPanel?.controls.suffixType.modify({
    controlType: 'radioButtons',
  });
  layoutPanel?.controls.labelPlacement.modify({
    controlType: 'radioButtons',
  });
  settingsPanel?.controls.prefixIcon.isVisible(
    ({ props: { prefixType } }) => prefixType === 'Icon',
  );
  settingsPanel?.controls.prefixValue.isVisible(
    ({ props: { prefixType } }) => prefixType === 'Text',
  );
  settingsPanel?.controls.suffixIcon.isVisible(
    ({ props: { suffixType } }) => suffixType === 'Icon',
  );
  settingsPanel?.controls.suffixValue.isVisible(
    ({ props: { suffixType } }) => suffixType === 'Text',
  );
  settingsPanel?.controls.suffixIcon.modify({
    preset: 'WSR_ICONS',
  });
  settingsPanel?.controls.prefixIcon.modify({
    preset: 'WSR_ICONS',
  });
});

export type NumberInputSDKActions = {
  onInvalid(payload: OnInvalidPayload): void;
  onKeyPress(payload: OnKeyPressPayload): void;
  onChange(paylod: OnChangePayload): void;
};

type IWsrNumberInputVeloProps = {
  error: boolean;
  warning: boolean;
  loading: boolean;
  errorMessage: string;
  warningMessage: string;
  loadingMessage: string;
  disabled: boolean;
  readOnly: boolean;
  validationMessage: string;
};

export type IWsrNumberInputProps = InferExtendedPropsSchema<typeof props> &
  NumberInputSDKActions &
  IWsrNumberInputVeloProps & { className?: string };

export default props;
