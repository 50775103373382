import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      divider: 'long',
      color: '#EEE',
      opacitySliderEnabled: true,
      showPercentage: true,
      opacity: 100,
      tooltip: '',
      label: 'Opacity & Color',
    },
    ...compData,
  };
});

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({ props, stateRefs });
