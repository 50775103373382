import * as React from 'react';
import {
  Composites,
  TextLabel,
  InfoIcon,
  TextInput,
} from '@wix/wix-base-ui/lib/esm';
import type { IWbuTextInputProps } from '../WbuTextInput.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import './WbuTextInput.global.scss';
import { getDataAttributes } from '../../../common/utils';

const WbuTextInput: React.FC<IWbuTextInputProps> = props => {
  const {
    id,
    className,
    placeholder,
    readOnly,
    prefix,
    charactersLimit,
    fieldTitle,
    tooltipInfo,
    defaultText,
    updateComponentPropsInViewer,
    onChange,
    selectedOnFocus,
    autofocus,
    usePatternValidation,
    errorMessage,
    regexPattern,
    divider,
    editorType,
  } = props;

  const _onChange = (val: string) => {
    updateComponentPropsInViewer({ defaultText: val });
    // Velo actually passes real event to the listener, we can pass anything that we wish to here.
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  const invalidMessageCheckFallback = 'Error...'; // should be translated.

  const invalidMessageCheck =
    usePatternValidation && errorMessage ? errorMessage : '';
  const isValid = usePatternValidation
    ? !!defaultText.match(new RegExp(regexPattern))
    : true;

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      divider={divider}
      editorType={editorType}
    >
      <Composites.TextInputLabeled>
        {tooltipInfo && <InfoIcon text={tooltipInfo} />}
        {fieldTitle && <TextLabel type="T02" value={fieldTitle} />}
        <TextInput
          prefix={prefix}
          placeholder={placeholder}
          isReadOnly={readOnly}
          value={defaultText}
          onChange={_onChange}
          maxLength={charactersLimit}
          selectOnFocus={selectedOnFocus}
          autoSelect={autofocus}
          invalidMessage={invalidMessageCheck || invalidMessageCheckFallback}
          isValid={isValid}
        />
      </Composites.TextInputLabeled>
    </WbuContainer>
  );
};

export default WbuTextInput;
