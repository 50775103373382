import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

import { IWbuThumbnailsProps } from '../WbuThumbnails.props';

const props = withCompInfo<IWbuThumbnailsProps, any>()(
  ['compData'],
  ({ compData }) => ({
    ...compData,
    options: JSON.parse(JSON.stringify(compData.options)),
  }),
);

export default createComponentMapperModel({
  props,
});
