import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      labelPlacement: 'On top',
      border: 'Standard',
      size: 'Medium',
      required: false,
      activeState: 'Active',
      loadingStatusMessage: '',
      warningStatusMessage: '',
      errorStatusMessage: '',
      status: 'Default',
      stepSize: 1,
      invalidMessage: 'Invalid value',
      maximumValue: 500,
      minimumValue: 0,
      minAndMaxValues: false,
      suffixValue: '',
      suffixIcon: {},
      suffixType: 'None',
      prefixValue: '',
      prefixIcon: {},
      prefixType: 'None',
      value: 0,
      placeholderText: '',
      infoContent: '',
      showTooltip: false,
      label: 'Number Input',
    },
    ...compData,
  };
});

const css = withCompInfo()([], () => {
  return {
    height: 'auto',
  };
});

export default createComponentMapperModel({
  props,
  css,
});
