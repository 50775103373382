import {
  defineLeanCompProps,
  InferExtendedPropsSchema,
} from '@wix/lean-components-infra';

export const PRESETS: {
  [key: string]: {
    value: string;
    presetValue: string;
    secondary?: boolean | undefined;
    light?: boolean | undefined;
  };
} = {
  STANDARD: {
    value: 'Standard',
    presetValue: 'standard',
  },
  STANDARD_SECONDARY: {
    value: 'Standard secondary',
    presetValue: 'standard',
    secondary: true,
  },
  LIGHT: { value: 'Light', presetValue: 'standard', light: true },
  LIGHT_SECONDARY: {
    value: 'Light secondary',
    presetValue: 'standard',
    secondary: true,
    light: true,
  },
  SUCCESS: { value: 'Success', presetValue: 'success' },
  DESTRUCTIVE: { value: 'Destructive', presetValue: 'error' },
  PRIMARY: { value: 'Primary', presetValue: 'primary' },
  PREMIUM: { value: 'Premium', presetValue: 'premium' },
  DISABLED: { value: 'Disabled', presetValue: 'disabled' },
};

const presets = Object.values(PRESETS).map(({ value }) => value);

const SIZES = {
  MEDIUM: 'Medium',
  SMALL: 'Small',
  TINY: 'Tiny',
};
const sizes = Object.values(SIZES);

export const WEIGHTS: { [key: string]: string } = {
  thin: 'Regular',
  normal: 'Medium',
  bold: 'Bold',
};

const weights = Object.values(WEIGHTS);

const ALIGNS = {
  LEFT: 'Left',
  CENTER: 'Center',
  RIGHT: 'Right',
};

const aligns = Object.values(ALIGNS);

const props = defineLeanCompProps(({ schemaBuilder }) => ({
  text: schemaBuilder
    .longString()
    .default("I'm a text. Add your own text and edit me. It's easy."),
  size: schemaBuilder.stringEnum(sizes).default(SIZES.MEDIUM),
  weight: schemaBuilder.stringEnum(weights).default(WEIGHTS.thin),
  textAlignment: schemaBuilder.stringEnum(aligns).default(ALIGNS.LEFT),
  preset: schemaBuilder.stringEnum(presets).default(PRESETS.STANDARD.value),
  ellipsis: schemaBuilder.boolean().default(true),
  maxLines: schemaBuilder.integer().default(1),
})).definePanels(({ settingsPanel }) => {
  settingsPanel?.controls.createSection(['size'], {
    name: 'dsgnsys_WsrText_size_section_label',
  });
  settingsPanel?.controls.createSection(['weight', 'textAlignment', 'preset'], {
    name: 'dsgnsys_WsrText_style_section_label',
  });
  settingsPanel?.controls.createSection(['ellipsis', 'maxLines'], {
    name: 'dsgnsys_WsrText_textOverflow_section_label',
  });
  settingsPanel?.controls.size.modify({
    controlType: 'radioButtons',
  });
  settingsPanel?.controls.weight.modify({
    controlType: 'radioButtons',
  });
  settingsPanel?.controls.textAlignment.modify({
    controlType: 'radioButtons',
  });
  settingsPanel?.controls.maxLines.isVisible(
    ({ props: { ellipsis } }) => ellipsis,
  );
});

export type IWsrTextProps = InferExtendedPropsSchema<typeof props> & {
  className?: string;
};

export default props;
