import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      skin: 'standard',
      underline: 'none',
      weight: 'thin',
      size: 'medium',
      disabled: false,
      suffixIcon: {},
      prefixIcon: {},
      linkTo: '',
      value: 'Text Button',
    },
    ...compData,
  };
});

const css = withCompInfo()([], () => {
  return {
    height: 'auto',
    width: 'auto',
  };
});

export default createComponentMapperModel({
  props,
  css,
});
