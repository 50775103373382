import * as React from 'react';
import InfoIcon, { InfoIconSize } from 'wix-style-react/dist/src/InfoIcon';
import TextButton from 'wix-style-react/dist/src/TextButton';
import Text from 'wix-style-react/dist/src/Text';
import type { IWsrInfoIconProps } from '../WsrInfoIcon.props';
import { getDataAttributes } from '../../../common/utils';
import { usePlatformLink } from '../../../providers';
import { classes } from './WsrInfoIcon.st.css';

const WsrInfoIcon: React.FC<IWsrInfoIconProps> = props => {
  const {
    id,
    className,
    tooltipText,
    size,
    actionText,
    actionLink,
    onTooltipLinkClick,
  } = props;

  const linkProps = usePlatformLink(actionLink);

  const renderContent = React.useMemo(() => {
    const handleActionClick: React.MouseEventHandler<HTMLElement> = () => {
      onTooltipLinkClick?.({ type: 'tooltipLinkClick' });
    };
    return (
      <div className={classes.tooltipContent}>
        {tooltipText && (
          <Text light size="small">
            {tooltipText}
          </Text>
        )}
        {actionText && (
          <TextButton
            {...linkProps}
            as={linkProps.href ? 'a' : 'button'}
            skin="light"
            size="small"
            underline="always"
            onClick={handleActionClick}
          >
            {actionText}
          </TextButton>
        )}
      </div>
    );
  }, [linkProps, tooltipText, actionText, onTooltipLinkClick]);

  return (
    <div id={id} {...getDataAttributes(props)} className={className}>
      <InfoIcon
        size={size.toLowerCase() as InfoIconSize}
        content={renderContent}
      />
    </div>
  );
};

export default WsrInfoIcon;
