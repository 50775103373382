import * as React from 'react';
import { DrillInListItem, InfoIcon } from '@wix/wix-base-ui/lib/esm';
import { IWbuDrillDownProps } from '../WbuDrillDown.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import { Constants } from '../constants';

import './WbuDrillDown.global.scss';
import { getDataAttributes } from '../../../common/utils';

const WbuDrillDown: React.FC<IWbuDrillDownProps> = props => {
  const { id, className, label, tooltip, description, iconType, editorType } =
    props;

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      editorType={editorType}
    >
      <DrillInListItem
        label={label || 'Label Title'}
        description={description}
        contentAfter={
          tooltip.length > 0 &&
          iconType !== Constants.IconTypeNone && (
            <InfoIcon
              error={iconType === Constants.IconTypeAlert}
              small
              text={tooltip}
            />
          )
        }
      />
    </WbuContainer>
  );
};

export default WbuDrillDown;
