import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      size: 'medium',
      textAlignment: 'left',
      weight: 'thin',
      preset: 'dark',
      value: "I'm a text. Add your own text and edit me. It's easy.",
    },
    ...compData,
  };
});

const css = withCompInfo<any, any>()(['compLayout'], ({ compLayout }) => {
  return {
    '--width': `${compLayout.width}px`,
    height: 'auto',
  };
});

export default createComponentMapperModel({
  props,
  css,
});
