import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuHorizontalTabsProps,
  IWbuHorizontalTabsMapperProps,
  IWbuHorizontalTabsControllerProps,
  IWbuHorizontalTabsStateValues,
} from '../../../components/WbuHorizontalTabs/WbuHorizontalTabs.props';

export default withCompController<
  IWbuHorizontalTabsMapperProps,
  IWbuHorizontalTabsControllerProps,
  IWbuHorizontalTabsProps,
  IWbuHorizontalTabsStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
