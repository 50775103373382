import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWsrHeadingProps,
  IWsrHeadingMapperProps,
  IWsrHeadingControllerProps,
  IWsrHeadingStateValues,
} from '../../../components/WsrHeading/WsrHeading.props';

export default withCompController<
  IWsrHeadingMapperProps,
  IWsrHeadingControllerProps,
  IWsrHeadingProps,
  IWsrHeadingStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
