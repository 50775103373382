import * as React from 'react';
import { AvatarGroupBase } from 'wix-ui-tpa/dist/src/components/AvatarGroup/AvatarGroup.base';
import { AvatarBase } from 'wix-ui-tpa/dist/src/components/Avatar/Avatar.base';
import type { AvatarGroupItem } from 'wix-ui-tpa/dist/src/components/AvatarGroup';
import classNames from 'classnames';
import type { IWutAvatarGroupProps } from '../WutAvatarGroup.props';
import { defaultAvatars, TestIds } from '../constants';
import { st, classes } from './style/WutAvatarGroup.component.st.css';

export type WutAvatarItem = AvatarGroupItem & {
  firstName?: string;
  lastName?: string;
};

const getAvatarName = (avatar: WutAvatarItem): AvatarGroupItem => {
  if (avatar.firstName && avatar.lastName) {
    return {
      ...avatar,
      name: `${avatar.firstName} ${avatar.lastName}`,
    };
  }

  return {
    ...avatar,
    name: avatar.firstName || avatar.lastName || '',
  };
};

const renderAvatar = (avatarProps: AvatarGroupItem) => (
  <div className={classes.avatarWrapper}>
    <AvatarBase lettersLimit={2} className={classes.avatar} {...avatarProps} />
  </div>
);

const combineAvatarsNames = (
  avatars: Array<WutAvatarItem>,
): Array<AvatarGroupItem> => avatars.map(getAvatarName);

const WutAvatarGroup: React.FC<IWutAvatarGroupProps> = props => {
  const {
    id,
    className,
    stylableClassName,
    maxAvatars,
    showHiddenAvatarCount,
    avatars,
  } = props as IWutAvatarGroupProps & {
    className: string;
    stylableClassName: string;
  }; // todo: remove it once this prop would be added for the lean

  return (
    <div
      className={classNames(className, classes.avatarContainerLayout)}
      id={id}
    >
      <AvatarGroupBase
        data-hook={TestIds.baseAvatarGroup}
        avatarContainerClassName={classes.avatarContainer}
        className={st(classes.root, stylableClassName)}
        items={
          avatars.length
            ? combineAvatarsNames(avatars as Array<WutAvatarItem>)
            : defaultAvatars
        }
        maxAmount={maxAvatars}
        renderHiddenAvatarCount={
          showHiddenAvatarCount ? renderAvatar : undefined
        }
        renderAvatar={renderAvatar}
      />
    </div>
  );
};

export default WutAvatarGroup;
