import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWsrCustomModalLayoutProps,
  IWsrCustomModalLayoutMapperProps,
  IWsrCustomModalLayoutControllerProps,
  IWsrCustomModalLayoutStateValues,
} from '../../../components/WsrCustomModalLayout/WsrCustomModalLayout.props';

export default withCompController<
  IWsrCustomModalLayoutMapperProps,
  IWsrCustomModalLayoutControllerProps,
  IWsrCustomModalLayoutProps,
  IWsrCustomModalLayoutStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
