import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      textAlignment: 'left',
      mode: 'dark',
      titleText: 'H2',
      value: 'Heading',
    },
    ...compData,
  };
});

const css = withCompInfo()(['compLayout'], ({ compLayout }) => {
  return {
    '--width': `${compLayout.width}px`,
    height: 'auto',
  };
});

export default createComponentMapperModel({
  props,
  css,
});
