type Dataset = Record<string, string>;

export const getDataAttributes = (props: any): Dataset => {
  return Object.entries(props).reduce((acc: Dataset, [key, val]) => {
    if (key.includes('data-')) {
      acc[key] = val as string;
    }
    return acc;
  }, {});
};

export const toStringPx = (value: number) => `${value}px`;

export const stringPxToNumber = (value?: string) =>
  value ? Number(value.replace('px', '')) : 0;
