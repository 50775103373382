import * as React from 'react';
import Dropdown, { DropdownProps } from 'wix-style-react/dist/src/Dropdown';
import FormField from 'wix-style-react/dist/src/FormField';
import classNames from 'classnames';
import type { IWsrDropdownProps } from '../WsrDropdown.props';
import { usePlatformIcon } from '../../../providers/usePlatformIcon';
import { getDataAttributes } from '../../../common/utils';
import { classes } from './WsrDropdown.st.css';

const WsrDropdown: React.FC<IWsrDropdownProps> = props => {
  const {
    id,
    className,
    label,
    placeholder,
    prefix,
    suffix,
    selectedId,
    onSelect,
    options,
    size,
    labelPosition,
    readOnly,
    autoFocus,
    required,
    infoContent,
    updateComponentPropsInViewer,
  } = props;

  const [selected, setSelected] = React.useState<string | number>(selectedId);
  const platformPrefixIcon = usePlatformIcon(prefix);
  const platformSuffixIcon = usePlatformIcon(suffix);

  React.useEffect(() => {
    setSelected(selectedId);
  }, [selectedId]);

  const handleOnSelect: DropdownProps['onSelect'] = option => {
    setSelected(option.id);
    updateComponentPropsInViewer({ selectedId: option.id + '' });
    onSelect?.({ type: 'select', selected: option });
  };

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(className, classes.root)}
    >
      <FormField
        label={label}
        infoContent={infoContent}
        labelPlacement={labelPosition}
        required={required}
        stretchContent
      >
        <Dropdown
          placeholder={placeholder}
          selectedId={selected}
          onSelect={handleOnSelect}
          size={size}
          autoFocus={autoFocus}
          readOnly={readOnly}
          prefix={platformPrefixIcon}
          suffix={platformSuffixIcon}
          options={options}
        />
      </FormField>
    </div>
  );
};

export default WsrDropdown;
