import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuCheckboxGroupProps,
  IWbuCheckboxGroupMapperProps,
  IWbuCheckboxGroupControllerProps,
  IWbuCheckboxGroupStateValues,
} from '../../../components/WbuCheckboxGroup/WbuCheckboxGroup.props';

export default withCompController<
  IWbuCheckboxGroupMapperProps,
  IWbuCheckboxGroupControllerProps,
  IWbuCheckboxGroupProps,
  IWbuCheckboxGroupStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
